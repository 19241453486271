import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TimeState = {
  day: number;
  season: number;
  year: number;
}

const initialState: TimeState = { day: 0, season: 0, year: 0 };

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    updateTime: (state, action: PayloadAction<TimeState>) => {
      state.day = action.payload.day;
      state.season = action.payload.season;
      state.year = action.payload.year;
    },
  },
});

export const { updateTime } = timeSlice.actions;

export default timeSlice.reducer;
