import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  gap: 32px;
  padding: 32px;

  & h1 {
    font-size: 32px;
  }
`;

export const FormPanel = styled.div`
  padding: 16px;
  border: 1px solid #444444;
  border-radius: 5px;
  background-color: #101010;

  & form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & div.sign-form-bottom {
    display: flex;
    justify-content: space-between;
  }
`;

export const FormPlaceholder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: min-content;
`;
