import styled from 'styled-components';

export const ButtonAsLinkElement = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: inline;
  color: #1F6FEB;
  outline: none;
  cursor: pointer;
  font: inherit;
  text-decoration: underline;
`;
