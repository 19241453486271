import styled from 'styled-components';

export const InlineButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ButtonTitle = styled.div`
  font-size: 28px;
`;

export const ButtonErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #f5b7b1;
`;
