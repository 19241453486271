import React from 'react';
import { ButtonElement } from './styled';

type ButtonProps = React.PropsWithChildren & React.ComponentPropsWithoutRef<"button">;

const Button = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <ButtonElement
      {...rest}
    >
      {children}
    </ButtonElement>
  )
};

export default Button;
