import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from '../../@types/resource';

type ResourcesState = {
  list: Array<Resource>;
}

const initialState: ResourcesState = { list: [] };

export const resourcesSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    updateResources: (state, action: PayloadAction<Array<Resource>>) => {
      state.list = action.payload;
    },
  },
});

export const { updateResources } = resourcesSlice.actions;

export default resourcesSlice.reducer;
