const ReligionIcon = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt" height="1280.000000pt" viewBox="0 0 1280.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>
      Created by potrace 1.15, written by Peter Selinger 2001-2017
    </metadata>
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M6090 12534 c-883 -44 -1693 -246 -2418 -604 -635 -313 -1146 -683
-1683 -1220 -1069 -1067 -1655 -2308 -1794 -3795 -22 -238 -30 -735 -16 -980
78 -1331 506 -2470 1306 -3470 280 -349 669 -743 1015 -1026 886 -725 1894
-1159 3035 -1308 299 -39 429 -46 870 -46 460 1 593 9 955 61 1137 162 2166
630 3055 1390 193 164 595 566 759 759 605 708 1027 1507 1251 2368 309 1191
269 2518 -110 3662 -317 956 -904 1845 -1705 2581 -1050 964 -2228 1484 -3640
1609 -167 15 -721 27 -880 19z m-141 -848 c-2 -2 -53 -25 -114 -50 -560 -238
-1123 -701 -1404 -1153 -235 -379 -358 -823 -380 -1369 -32 -790 142 -1362
564 -1852 156 -181 413 -398 660 -558 228 -147 595 -288 1140 -439 704 -195
876 -254 1145 -391 69 -35 159 -85 200 -112 41 -27 116 -72 165 -102 395 -233
696 -560 910 -987 122 -245 201 -498 247 -793 18 -114 18 -599 0 -740 -23
-182 -62 -360 -113 -516 -190 -578 -549 -979 -1124 -1255 -378 -182 -772 -281
-1360 -341 -73 -8 -274 -13 -515 -13 -415 0 -507 7 -785 56 -678 122 -1390
456 -1991 934 -1176 936 -1894 2146 -2088 3518 -44 309 -51 415 -51 792 0 377
7 487 51 793 137 964 544 1858 1207 2652 909 1089 2055 1733 3377 1899 91 11
181 22 200 24 19 2 41 5 49 5 8 1 12 0 10 -2z m576 -1850 c126 -41 218 -98
316 -195 74 -73 93 -100 132 -181 25 -52 51 -120 58 -150 20 -82 17 -300 -5
-380 -55 -201 -203 -378 -391 -470 -100 -49 -182 -69 -310 -76 -220 -12 -403
54 -568 206 -91 84 -156 184 -193 296 -27 83 -29 100 -29 239 1 143 2 154 32
232 87 228 281 414 508 486 77 24 90 26 235 23 114 -3 148 -8 215 -30z"/>
      <path d="M6130 4301 c-162 -35 -293 -106 -419 -225 -116 -112 -199 -251 -242
-411 -32 -115 -32 -345 0 -460 85 -311 327 -549 639 -627 121 -31 313 -30 427
1 255 69 477 256 595 503 145 302 87 700 -137 939 -131 138 -252 215 -420 265
-120 36 -317 43 -443 15z"/>
    </g>
  </svg>
);

export default ReligionIcon;
