import styled from 'styled-components';

export const SignErrorPlaseholder = styled.div`
  height: 56px;
  width: 100%;
`;

export const SignErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 16px;
  background-color: rgba(255, 0, 0, .1);
  border: 1px solid #960018;
  border-radius: 6px;
  box-sizing: border-box;
  color: #EE0800;
  height: 100%;
  width: 100%;
`;

export const InvisibleButton = styled.button`
  border: none;
  background: none;
  display: contents;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
