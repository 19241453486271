import React, { useEffect } from 'react';
import { CenteredWrapper, EmptyPlayer, GalaxyCreatedDate, GalaxyListWrapper, GalaxyName, GalaxyPanel, GalaxyPlaceholder, PlayerCreatedDate, PlayerName, PlayerPlaceholder, Titile } from './styled';
import { useDispatch } from 'react-redux';
import { socketSendAction } from '../../utils/socketMiddleware';
import { useAppSelector } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const GalaxyPage = () => {
  const dispatch = useDispatch();
  const galaxies = useAppSelector((state) => state.galaxy.galaxies);
  const players = useAppSelector((state) => state.galaxy.players);
  const updateAt = useAppSelector((state) => state.galaxy.updateAt);
  const navigate = useNavigate();

  const { playerId } = useAppSelector((state) => state.sync);

  useEffect(() => {
    if (playerId) {
      navigate('/');
    }
  }, [navigate, playerId]);

  useEffect(() => {
    dispatch(socketSendAction({ action: 'req:galaxy:list-for-choose' }));
  }, [dispatch]);

  useEffect(() => {
  }, [galaxies]);

  const onChooseGalaxy = async (galaxyId: string) => {
    dispatch(socketSendAction({ action: 'req:galaxy:choose', payload: { galaxyId } }));
  }

  if (updateAt && galaxies && galaxies.length === 0) {
    return (
      <CenteredWrapper>
        <h1>Наша вселенная еще не зародилась. Кина не будет :(</h1>
      </CenteredWrapper>
    );
  }

  return (
    <CenteredWrapper>
        <GalaxyListWrapper>
          <Titile>Какую галактику полетим осваивать?</Titile>
          <GalaxyListWrapper>
            {Array.isArray(galaxies) && galaxies.map((galaxy) => {
              const player = players?.find((player) => player.galaxyId === galaxy._id);
              return (
              <GalaxyPanel
                key={galaxy._id}
                onClick={() => {
                  onChooseGalaxy(galaxy._id);
                }}
              >
                <GalaxyPlaceholder>
                  <GalaxyName>{galaxy.name}</GalaxyName>
                  <GalaxyCreatedDate>зародилась {dayjs(galaxy.createdAt).format('DD.MM.YY')}</GalaxyCreatedDate>
                </GalaxyPlaceholder>
                <PlayerPlaceholder>
                  {player ? (
                    <>
                      <PlayerName>{player.name}</PlayerName>
                      <PlayerCreatedDate>создан {dayjs(player.createdAt).format('DD.MM.YY')}</PlayerCreatedDate>
                    </>
                  ) : (
                    <EmptyPlayer>Ждет своего героя</EmptyPlayer>
                  )}
                </PlayerPlaceholder>
              </GalaxyPanel>
            )}
            )}
          </GalaxyListWrapper>
        </GalaxyListWrapper>
    </CenteredWrapper>
  );
  
};

export default GalaxyPage;
