import { createSlice } from '@reduxjs/toolkit';

export type SocketState = {
  action?: string;
  payload?: Object;
};

const initialState: SocketState = {};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    socketGetMessage: (state, action) => {
      state.action = action.payload.action;
      state.payload = action.payload.payload;
    }
  }
});

export const { socketGetMessage } = socketSlice.actions;

export default socketSlice.reducer;
