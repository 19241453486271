import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Galaxy } from '../../@types/galaxy';

interface GalaxyState {
  galaxies?: Array<Galaxy>;
  players?: Array<any>;
  updateAt?: number;
  message?: string;
}

const initialState: GalaxyState = {};

type UpdateGalaxiesPayload = {
  result: 'OK' | 'ERROR',
  payload: {
    galaxies: Array<Galaxy>,
    players: Array<any>,
  }
}

export const galaxySlice = createSlice({
  name: 'galaxy',
  initialState,
  reducers: {
    updateGalaxies: (state, action: PayloadAction<UpdateGalaxiesPayload>) => {
      if (action.payload.result === 'OK') {
        state.galaxies = action.payload.payload.galaxies;
        state.players = action.payload.payload.players;
        state.message = undefined;
      } else {
        state.galaxies = [];
        state.players = [];
        state.message = action.payload.payload as unknown as string;
      }
      state.updateAt = (new Date()).getTime();
    },
  },
});

export const { updateGalaxies } = galaxySlice.actions;

export default galaxySlice.reducer;
