import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SignState = {
  angle: number;
}

const initialState: SignState = { angle: 0 };

export const signSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {
    signRotate: (state, action: PayloadAction<number>) => {
      const { payload: delta = 1 } = action;
      state.angle = state.angle + delta < 0 ? 360 + (state.angle + delta) : (state.angle + delta > 360 ? (state.angle + delta) - 360 : state.angle + delta);
    },
  },
});

export const { signRotate } = signSlice.actions;

export default signSlice.reducer;
