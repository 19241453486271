import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { Header } from './styled';
import { useAppDispatch } from '../../store';
import Calendar from '../../components/Calendar';
import HomeMenu from '../../components/HomeMenu';

const GamePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const playerId = useAppSelector((state) => state.sync.playerId);

  useEffect(() => {
    if (!playerId) {
      navigate('/galaxy');
    }
  }, [navigate, playerId]);

  const onClick = () => {
    dispatch({ type: 'socket/send', payload: { action: 'req:user:logout' } });
  }

  const onPlayerSwitchClick = () => {
    dispatch({ type: 'socket/send', payload: { action: 'req:player:switch' } });
  }

  const onResourceGather = (resourceId: string) => {
    dispatch({ type: 'socket/send', payload: { action: 'req:resource:gather', payload: { resourceId: resourceId, amount: 100 } } });
  }

  return (
    <div>
      <Header>
        <div>{playerId}</div>
        <div>
          <Calendar />
        </div>
        <button type="button" onClick={onClick}>Log out</button>
      </Header>
      <HomeMenu />
      <br />
      <br />
      <br />
      <br />
      <button type="button" onClick={onPlayerSwitchClick}>Switch Player</button>
      <button type="button" onClick={() => { onResourceGather('base') }}>Gather 100 base res</button>
      <button type="button" onClick={() => { onResourceGather('wood') }}>Gather 100 wood res</button>
    </div>
  );
};

export default GamePage;
