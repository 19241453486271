import styled from 'styled-components';

export const InputElement = styled.input`
  border: 1px solid #444444;
  border-radius: 6px;
  padding: 9px 12px;
  color: #AAAAAA;
  background-color: #000000;
  font-weight: 300;
  font-size: 26px;
  
  &:focus {
    border-color: #1F6FEB;
    border-width: 2px;
    padding: 8px 11px;
  }

  &:focus-visible {
    outline: none;
  }
`;
