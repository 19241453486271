import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SignInPage from './pages/signin/SignIn';
import SignUpPage from './pages/signup/SignUp';
import GamePage from './pages/game/Game';
import ProtectedRoute from './components/ProtectedRoute';
import GalaxyPage from './pages/galaxy';
import { socketConnectAction } from './utils/socketMiddleware';
import { apiMethods, callApi } from './utils/api';
import ConfirmPage from './pages/confirm';
import { useAppDispatch, useAppSelector } from './store';

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId, playerId, status } = useAppSelector((store) => store.sync);

  useEffect(() => {
    const getGameVersion = async () => {
      try {
        await callApi(apiMethods.GET, '/api/v1/version', {});
        dispatch(socketConnectAction());
      } catch (e) {
        // @TODO: global error
      }
    }

    getGameVersion();
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      if (userId && !playerId) {
        navigate('/galaxy');
      }
    }
  }, [navigate, playerId, status, userId]);

  if (status === 'idle' || (status === 'pending' && !userId)) {
    return <div></div>;
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route index element={<GamePage />} />
        <Route path="/galaxy" element={<GalaxyPage />} />
      </Route>
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/confirm/:id" element={<ConfirmPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
