export enum apiMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  PUT = 'PUT'
}

type CallApiProps = {
  body?: { [key: string]: any };
  token?: string | null;
  headers?: Record<string, unknown>;
  customResponseHandler?: (data: string) => void;
  customErrorHandler?: (data: string, status: number) => void;
  [key: string]: any;
}

const getAuthorizationHeader = (token?: string | null) => token ? { Authorization: `Bearer ${token}`} : undefined;
const getContentTypeHeader = (data: unknown | undefined) => data ? { 'Content-Type': 'application/json' } : undefined;

export const callApi = async (method: apiMethods, url: string, {body, token, headers: customHeaders, customErrorHandler, customResponseHandler, ...rest}: CallApiProps) => {
  const params = {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      ...getContentTypeHeader(body),
      ...getAuthorizationHeader(token),
      ...customHeaders,
    },
    ...rest,
  };

  try {
    const response = await fetch(url, {...params, credentials: 'include'});
    const data = await response.text();
    if (response.ok) {
      if (customResponseHandler) {
        customResponseHandler(data);
      } else {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }
    } else {
      if (customErrorHandler) {
        customErrorHandler(data, response.status);
      } else {
        throw new Error(data);
      }
    }
  } catch (e) {
    throw e;
  }
};
