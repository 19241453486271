import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfirmState {
  loading: boolean;
  result?: string;
  message?: string;
}

const initialState: ConfirmState = { loading: true };

type UpdateConfirmPayload = {
  result: 'OK' | 'ERROR',
  payload?: string
}

export const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    updateConfirm: (state, action: PayloadAction<UpdateConfirmPayload>) => {
      if (action.payload.result === 'ERROR') {
        state.message = action.payload.payload;
      }
      state.result = action.payload.result;
      state.loading = false;
    },
  },
});

export const { updateConfirm } = confirmSlice.actions;

export default confirmSlice.reducer;
