import React from 'react';
import { useParams } from 'react-router-dom';

const ConfirmPage = () => {
  const { id } = useParams();

  return (
    <div>Confirm [{id}]</div>
  );
};

export default ConfirmPage;
