import React from 'react';
import { ButtonAsLinkElement } from './styled';

type ButtonAsLinkProps = {} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonAsLink: React.FC<ButtonAsLinkProps> = (props: ButtonAsLinkProps) => {
  const { children, ...rest } = props;
  return (
    <ButtonAsLinkElement {...rest}>{children}</ButtonAsLinkElement>
  )
};

export default ButtonAsLink;
