const AwardIcon = () => (
  <svg fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 32.001 32.001">
    <g>
      <path d="M27.803,11.343c0.153-0.482,0.105-0.903-0.075-1.238c-0.169-0.338-0.427-0.6-0.74-0.794
		c-0.035-0.023-0.07-0.043-0.104-0.064c-0.06-0.243-0.118-0.487-0.194-0.726c0.126-0.493,0.229-0.937,0.198-1.185
		c-0.021-0.505-0.21-0.884-0.494-1.137c-0.275-0.26-0.605-0.417-0.968-0.493c-0.036-0.009-0.07-0.011-0.106-0.019
		c-0.136-0.203-0.288-0.396-0.438-0.589c-0.045-0.52-0.116-0.969-0.22-1.205c-0.396-0.937-1.222-1.124-1.932-1.031
		c-0.043,0.003-0.084,0.011-0.126,0.017c-0.196-0.143-0.396-0.281-0.602-0.412c-0.22-0.471-0.439-0.867-0.618-1.053
		c-0.691-0.745-1.532-0.638-2.168-0.308c-0.034,0.016-0.067,0.036-0.103,0.053c-0.237-0.069-0.478-0.132-0.721-0.186
		c-0.363-0.363-0.7-0.651-0.928-0.766c-0.444-0.253-0.871-0.24-1.23-0.143c-0.363,0.089-0.664,0.333-0.912,0.594
		c-0.029,0.029-0.055,0.061-0.083,0.09c-0.247,0.017-0.492,0.041-0.734,0.073c-0.472-0.218-0.892-0.376-1.147-0.405
		c-0.505-0.085-0.9,0.073-1.206,0.287c-0.311,0.208-0.51,0.541-0.653,0.87c-0.017,0.035-0.029,0.071-0.044,0.107
		C11.23,1.78,11.009,1.886,10.792,2c-0.515-0.045-0.957-0.038-1.213,0.008c-0.496,0.101-0.816,0.383-1.035,0.68
		C8.333,3,8.258,3.381,8.229,3.731C8.225,3.773,8.226,3.815,8.224,3.857C8.049,4.024,7.884,4.201,7.722,4.378
		C7.214,4.514,6.794,4.673,6.566,4.807c-0.432,0.266-0.635,0.64-0.74,0.993c-0.091,0.365-0.03,0.749,0.062,1.089
		c0.011,0.041,0.025,0.08,0.038,0.12c-0.108,0.221-0.216,0.44-0.31,0.668c-0.416,0.297-0.755,0.581-0.921,0.78
		c-0.302,0.4-0.378,0.817-0.354,1.187c0.042,0.375,0.228,0.714,0.431,1.002c0.021,0.03,0.044,0.058,0.066,0.087
		c-0.026,0.244-0.034,0.494-0.044,0.742c-0.293,0.427-0.519,0.816-0.608,1.063c-0.146,0.479-0.075,0.897,0.074,1.235
		c0.168,0.338,0.459,0.594,0.748,0.794c0.034,0.024,0.069,0.044,0.104,0.066c0.06,0.241,0.118,0.482,0.193,0.717
		c-0.126,0.493-0.207,0.93-0.207,1.188c0.042,0.494,0.227,0.874,0.496,1.135c0.284,0.255,0.629,0.403,0.97,0.493
		c0.039,0.009,0.076,0.016,0.114,0.022c0.137,0.204,0.29,0.396,0.439,0.591c0.039,0.396,0.092,0.747,0.155,1.003
		c-1.061,1.988-3.548,6.82-2.851,7.151c1.885,0.084,4.524,0.587,4.524,0.587s1.592,2.765,1.885,3.016
		c0.998-3.263,2.634-5.616,3.676-6.884c0.01,0.005,0.022,0.016,0.032,0.02c0.44,0.225,0.84,0.239,1.193,0.144l4.313,8.187
		l2.137-3.477l5.235-0.838c0,0-2.002-5.453-3.646-7.641c0-0.011,0-0.02,0-0.029c0.173-0.166,0.337-0.342,0.499-0.519
		c0.503-0.139,0.948-0.275,1.152-0.429c0.21-0.137,0.377-0.293,0.504-0.457c0.115-0.173,0.188-0.356,0.233-0.54
		c0.089-0.367,0.059-0.733-0.057-1.085c-0.013-0.039-0.026-0.077-0.039-0.117c0.108-0.222,0.219-0.445,0.313-0.676
		c0.417-0.298,0.78-0.573,0.917-0.783c0.151-0.201,0.254-0.404,0.317-0.603c0.049-0.202,0.055-0.399,0.034-0.587
		c-0.042-0.375-0.196-0.709-0.425-1c-0.021-0.028-0.045-0.056-0.066-0.083c0.026-0.242,0.033-0.489,0.043-0.735
		C27.491,11.979,27.747,11.589,27.803,11.343z M24.071,16.053c-0.628,0.198-1.148,0.421-1.251,1.178
		c-0.01,0.183,0.002,0.499-0.011,0.892c-0.143,0.155-0.285,0.31-0.438,0.453c-0.062-0.002-0.126-0.005-0.188-0.006
		c-0.331-0.022-0.646-0.023-0.908,0.073c-0.267,0.094-0.504,0.244-0.672,0.607c-0.083,0.149-0.175,0.467-0.321,0.838
		c-0.184,0.098-0.371,0.188-0.562,0.271c-0.062-0.024-0.122-0.05-0.183-0.072c-0.303-0.135-0.6-0.244-0.878-0.243
		c-0.282-0.003-0.558,0.06-0.84,0.342c-0.129,0.111-0.323,0.378-0.586,0.675c-0.204,0.027-0.41,0.048-0.618,0.062
		c-0.047-0.044-0.095-0.088-0.143-0.131c-0.239-0.229-0.48-0.431-0.743-0.523c-0.264-0.103-0.543-0.136-0.905,0.033
		c-0.162,0.064-0.437,0.246-0.79,0.438c-0.202-0.045-0.401-0.099-0.599-0.154c-0.03-0.06-0.061-0.119-0.091-0.177
		c-0.146-0.298-0.304-0.569-0.52-0.746c-0.212-0.188-0.464-0.314-0.862-0.28c-0.172,0.006-0.488,0.082-0.879,0.141
		c-0.177-0.111-0.349-0.23-0.517-0.354c-0.007-0.062-0.015-0.126-0.022-0.188c-0.036-0.329-0.097-0.632-0.235-0.875
		c-0.12-0.266-0.343-0.442-0.711-0.563c-0.152-0.062-0.49-0.085-0.873-0.162c-0.129-0.167-0.259-0.332-0.377-0.507
		c0.016-0.066,0.032-0.132,0.047-0.198c0.079-0.321,0.125-0.626,0.079-0.901C8.454,15.685,8.306,15.441,8,15.203
		c-0.124-0.111-0.44-0.251-0.778-0.459c-0.063-0.196-0.112-0.398-0.162-0.6c0.037-0.054,0.074-0.107,0.112-0.161
		c0.341-0.553,0.619-1.062,0.198-1.708c-0.077-0.146-0.326-0.382-0.569-0.688c0.008-0.21,0.014-0.422,0.036-0.629
		c0.056-0.04,0.112-0.08,0.169-0.12c0.509-0.402,0.945-0.785,0.771-1.537C7.753,9.132,7.599,8.821,7.474,8.445
		c0.08-0.192,0.171-0.38,0.263-0.566C7.798,7.862,7.86,7.844,7.923,7.828c0.62-0.202,1.152-0.418,1.251-1.179
		c0.026-0.169-0.005-0.501,0.007-0.891C9.326,5.6,9.472,5.444,9.625,5.296c0.063,0.006,0.127,0.01,0.192,0.015
		c0.651,0.022,1.225,0.002,1.579-0.679c0.083-0.151,0.168-0.479,0.316-0.847c0.182-0.096,0.368-0.184,0.558-0.269
		c0.062,0.027,0.123,0.055,0.184,0.082c0.294,0.113,0.589,0.22,0.874,0.239c0.281,0.005,0.548-0.089,0.84-0.336
		c0.087-0.08,0.202-0.23,0.347-0.417c0.072-0.084,0.153-0.174,0.24-0.269c0.207-0.028,0.416-0.048,0.626-0.063
		c0.047,0.046,0.094,0.091,0.141,0.136C15.76,3.095,16,3.296,16.261,3.413c0.264,0.1,0.546,0.105,0.904-0.028
		c0.11-0.046,0.27-0.147,0.47-0.274c0.1-0.055,0.207-0.114,0.325-0.174c0.201,0.044,0.397,0.097,0.593,0.153
		c0.029,0.061,0.06,0.121,0.089,0.181c0.15,0.279,0.311,0.544,0.516,0.744c0.212,0.188,0.478,0.285,0.86,0.282
		C20.136,4.292,20.32,4.25,20.552,4.2c0.106-0.016,0.227-0.033,0.353-0.05c0.179,0.114,0.353,0.233,0.523,0.358
		c0.008,0.064,0.014,0.128,0.021,0.191c0.046,0.313,0.106,0.617,0.229,0.876c0.137,0.248,0.352,0.431,0.712,0.56
		c0.112,0.036,0.301,0.061,0.535,0.093c0.105,0.021,0.224,0.045,0.347,0.073c0.127,0.163,0.255,0.325,0.371,0.498
		c-0.017,0.066-0.031,0.133-0.046,0.198c-0.143,0.643-0.214,1.206,0.395,1.672c0.157,0.101,0.441,0.253,0.782,0.467
		c0.062,0.195,0.11,0.396,0.161,0.598c-0.036,0.055-0.072,0.109-0.106,0.164c-0.354,0.556-0.613,1.061-0.202,1.706
		c0.112,0.146,0.323,0.384,0.567,0.695c-0.008,0.205-0.014,0.411-0.035,0.613c-0.056,0.042-0.112,0.084-0.166,0.126
		c-0.522,0.4-0.936,0.787-0.772,1.533c0.055,0.178,0.177,0.477,0.3,0.857c-0.079,0.192-0.17,0.379-0.263,0.564
		C24.194,16.016,24.132,16.033,24.071,16.053z"/>
    </g>
  </svg>
);

export default AwardIcon;
