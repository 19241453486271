const ResidentsIcon = () => (
  <svg fill="currentColor" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 464.048 464.048">
    <g>
      <path d="M174.356,333.38c-5.194-22.919,8.382-46.127,30.901-52.845l48.483-14.45c-3.862-5.865-7.46-11.871-10.479-17.97
		c-13.121-4.362-24.632-16.484-30.355-32.851c-1.001-2.876-1.769-5.802-2.33-8.727l-21.928-6.537
		c-15.436,18.846-35.345,31.983-58.554,31.983c-23.208,0-43.117-13.137-58.555-31.983l-52.234,15.578
		c-10.853,3.236-18.299,13.224-18.299,24.546V261.4c0,71.293,57.787,129.088,129.088,129.088c19.737,0,38.363-4.559,55.098-12.48
		c-1.438-4.403-2.721-8.883-3.769-13.481L174.356,333.38z"/>
      <path d="M442.462,295.885l-54.05-16.107c-16.297,19.549-37.456,34.234-62.261,34.234c-24.805,0-45.964-14.685-62.276-34.234
		l-54.033,16.107c-14.529,4.332-23.209,19.183-19.863,33.961l7.07,31.131c13.699,60.289,67.279,103.071,129.103,103.071
		c61.822,0,115.403-42.782,129.104-103.063l7.069-31.139C465.671,315.067,456.991,300.217,442.462,295.885z"/>
      <path d="M49.052,146.927c4.956,6.242,11.268,9.999,17.297,9.886c13.611,31.221,37.611,58.029,65.025,58.029
		c27.412,0,51.411-26.808,65.023-58.029c6.03,0.112,12.34-3.645,17.296-9.886c2.622-3.295,4.895-7.209,6.466-11.728
		c2.947-8.457,2.902-16.84,0.581-23.274c-1.823-5.044-5.02-8.919-9.5-10.493c-0.731,0.238-1.46,0.5-2.253,0.562
		c-0.244,0.019-0.486,0.025-0.731,0.025c-1.453,0-2.802-0.443-4.067-1.055c-2.411-1.167-4.37-3.214-5.168-5.897
		c-0.193-0.644-5.076-15.811-33.911-15.811c-2.771,0-5.705,0.144-8.794,0.425c0,0-3.016,0.268-7.665,0.268
		c-15.381,0-36.097-2.659-47.437-15.304c-2.398-2.678-4.245-5.674-5.522-8.938c-10.663,6.772-25.992,19.568-31.889,39.672
		c-0.751,2.541-2.523,4.457-4.72,5.637c-1.367,0.736-2.858,1.248-4.476,1.248c-0.375,0-0.749-0.019-1.13-0.069
		c-0.774-0.093-1.466-0.393-2.172-0.662c-4.368,1.623-7.503,5.424-9.299,10.393c-2.323,6.435-2.366,14.817,0.579,23.274
		C44.159,139.718,46.431,143.632,49.052,146.927z"/>
      <path d="M103.304,40.833c-2.913,26.614,30.535,29.528,45.347,29.528c4.156,0,6.846-0.23,6.846-0.23
		c3.415-0.318,6.617-0.462,9.613-0.462c37.505,0,43.16,22.888,43.16,22.888l2.322-17.233c4.888-36.221-20.311-69.619-56.475-74.869
		C152.008,0.15,149.891,0,147.789,0c-12.084,0-23.742,4.969-32.133,13.919l-0.637,0.681c-5.549-2.534-11.473-3.782-17.369-3.782
		c-8.489,0-16.922,2.583-24.095,7.639c-13.649,9.618-21.407,25.566-20.565,42.237l1.61,31.982
		C65.507,55.525,103.304,40.833,103.304,40.833z"/>
      <g>
        <path d="M425.519,183.399c-1.99-5.768-5.343-11.235-10.668-11.98c-1.155-0.161-2.549,0.236-3.43,0.236
			c-1.335,0-4.4-1.655-5.191-2.246c-17.656-13.341-47.441-22.289-79.189-22.289c-31.749,0-61.501,8.948-79.155,22.289
			c-0.792,0.591-3.855,2.246-5.188,2.246c-0.883,0-2.277-0.397-3.432-0.236c-5.327,0.745-8.678,6.212-10.668,11.98
			c-2.454,7.081-2.446,16.203,0.763,25.416c1.691,4.855,4.114,9.085,6.902,12.672c5.52,7.087,12.623,11.374,19.396,11.246
			c14.913,34.202,41.439,64.06,71.398,64.06c29.961,0,56.486-29.857,71.398-64.06c6.775,0.128,13.878-4.158,19.398-11.246
			c2.788-3.587,5.213-7.817,6.903-12.672C427.965,199.602,427.972,190.48,425.519,183.399z"/>
      </g>
      <path d="M267.39,147.167l3.628-32.269l6.186,29.088c14.96-4.321,32.124-6.81,50.423-6.81c9.727,0,19.096,0.749,28.025,2.054
		l8.687-42.638l3.328,44.798c18.727,4.08,34.757,10.854,46.161,19.468c1.354-6.126,2.11-12.465,2.11-19.004
		c0-31.099-16.103-58.397-40.404-74.128c0.085-0.984,0.343-1.91,0.343-2.91c0-3.586-0.715-7.017-1.733-10.34
		c-5.356-17.464-23.705-30.415-45.732-30.415c-21.628,0-39.678,12.501-45.39,29.496c-1.219,3.601-2.068,7.33-2.068,11.259
		c0,0.692,0.193,1.332,0.235,2.011c-25.108,15.581-41.881,43.307-41.881,75.026c0,6.539,0.764,12.878,2.118,19.004
		C248.472,155.531,257.279,150.895,267.39,147.167z"/>
    </g>
  </svg>
);

export default ResidentsIcon;
