import styled from 'styled-components';

export const CenteredWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Titile = styled.h1`
  margin-bottom: 32px;
`;

export const GalaxyListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GalaxyPanel = styled.div`
  border: 1px solid #0243a6;
  border-radius: 5px;
  background-image: linear-gradient(90deg, rgba(2, 67, 166, 0.4) 0%, rgba(2, 67, 166, 0.05) 100%);
  cursor: pointer;
  padding: 16px 32px;
  display: flex;
  gap: 32px;
`;

export const GalaxyPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const GalaxyName = styled.div`
  font-size: 21px;
  font-weight: 300;
  color: #fffdc4;
  margin-bottom: 4px;
`;

export const GalaxyCreatedDate = styled.span`
  color: #326ed5;
  font-size: 11px;
  font-weight: 500;
`;

export const PlayerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const PlayerName = styled.div`
  font-size: 21px;
  font-weight: 300;
  color: #fffdc4;
  margin-bottom: 4px;
`;

export const PlayerCreatedDate = styled.span`
  color: #5B5B5B;
  font-size: 11px;
  font-weight: 500;
`;

export const EmptyPlayer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  color: #696969;
`;
