import { signRotate } from '../store/sign/sign';

let initialized = false;

export const signRotationMiddleware = () => (params: any) => (next: any) => (action: any) => {

  const signRotateTimeoutCallback = () => {
    params.dispatch(signRotate(.7));
    setTimeout(signRotateTimeoutCallback, 100);
  };

  if (!initialized) {
    initialized = true;
    signRotateTimeoutCallback();
  }

  return next(action);
};