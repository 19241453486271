import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HomeMenuPlaceholder = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const HomeMenuItem = styled.button`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 8px;

  background-color: transparent;
  border: none;
  color: #595959;

  cursor: pointer;

  &:hover {
    color: #fffdc4;
    font-weight: 600;
  }

  & svg {
    max-height: 32px;
    max-width: 32px;
  }
`;

export const HomeMenuTitle = styled.h4`
  min-width: 32px;
  font-weight: 400;
  font-size: 12px;
`;
