import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserPublic = {
  _id: string,
  email: string,
  nickname: string,
  roles: [string],
  lastPlayer: string,
}

interface UserState {
  data?: UserPublic;
  message?: string;
  visible: boolean;
  loading: boolean;
}

const initialState: UserState = { data: undefined, message: undefined, visible: false, loading: false };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserPublic | undefined>) => {
      state.data = action.payload ? {...action.payload} : action.payload;
      state.message = undefined;
      state.visible = false;
      state.loading = false;
    },
    signinUser: (state, action: PayloadAction<any>) => {
      if (action.payload.result === 'ERROR') {
        state.data = undefined;
        state.message = action.payload.message
        state.visible = true;
        state.loading = false;
      } else {
        state.data = action.payload.payload;
        state.message = undefined;
        state.visible = false;
        state.loading = false;
      }
    },
    signupUser: (state, action: PayloadAction<any>) => {
      if (action.payload.result === 'ERROR') {
        state.data = undefined;
        state.message = action.payload.message
        state.visible = true;
        state.loading = false;
      } else {
        state.data = action.payload.payload;
        state.message = undefined;
        state.visible = false;
        state.loading = false;
      }
    },
    hideMessage: (state) => {
      state.visible = false;
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.visible = true;
      state.message = action.payload;
    },
    resetError: (state) => {
      state.message = undefined;
      state.visible = false;
    },
    logoutUser: () => initialState,
  },
});


export const {
  updateUser,
  signinUser,
  signupUser,
  logoutUser,
  hideMessage,
  setError,
  resetError,
  setUserLoading,
} = userSlice.actions;

export default userSlice.reducer;
