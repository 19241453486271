import AwardIcon from './Icons/Award';
import ChallengeIcon from './Icons/Challenge';
import FireIcon from './Icons/Fire';
import ReligionIcon from './Icons/Religion';
import ResidentsIcon from './Icons/Residents';
import ScienceIcon from './Icons/Sience';
import SpaceIcon from './Icons/Space';
import StatIcon from './Icons/Stat';
import TimeIcon from './Icons/Time';
import TradeIcon from './Icons/Trade';
import WorkshopIcon from './Icons/Workshop';
import { HomeMenuItem, HomeMenuPlaceholder, HomeMenuTitle } from './styled';

const HomeMenu = () => {
  return (
    <HomeMenuPlaceholder>
      <HomeMenuItem>
        <FireIcon />
        <HomeMenuTitle>Костер</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <ResidentsIcon />
        <HomeMenuTitle>Жители</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <ScienceIcon />
        <HomeMenuTitle>Наука</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <WorkshopIcon />
        <HomeMenuTitle>Цех</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <TradeIcon />
        {
          // биржа
        }
        <HomeMenuTitle>Базар</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <ReligionIcon />
        <HomeMenuTitle>Религия</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <SpaceIcon />
        <HomeMenuTitle>Космос</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <TimeIcon />
        <HomeMenuTitle>Время</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <ChallengeIcon />
        <HomeMenuTitle>Вызовы</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <AwardIcon />
        <HomeMenuTitle>Рекорды</HomeMenuTitle>
      </HomeMenuItem>
      <HomeMenuItem>
        <StatIcon />
        <HomeMenuTitle>Статы</HomeMenuTitle>
      </HomeMenuItem>
    </HomeMenuPlaceholder>
  );
};

export default HomeMenu;
