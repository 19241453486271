import React from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const userId = useAppSelector((state) => state.sync.userId);

  if (!userId) {
    return <Navigate to="/signin" replace />
  }

  return <Outlet />
};

export default ProtectedRoute;
