import { Action, configureStore, createListenerMiddleware, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import Socket from '../utils/socket';

import socketReducer, { socketGetMessage } from './socket';
import signReducer from './sign/sign';
import syncReducer from './sync/sync';
import userReducer from './user/user';
import confirmReducer from './confirm/confirm';
import galaxyReducer from './galaxy/galaxy';
import timeReducer from './time';
import resourcesReducer from './resources';

import signInUserHandler from './actionHandlers/signInUser';
import syncHandler from './actionHandlers/sync';
import logoutHandler from './actionHandlers/logout';
import signUpUserHandler from './actionHandlers/signUpUser';
import galaxyListHandler from './actionHandlers/galaxyList';
import galaxyChooseHandler from './actionHandlers/galaxyChoose';
import playerSwitchHandler from './actionHandlers/playerSwitch';

import { socketMiddleware } from '../utils/socketMiddleware';
import { signRotationMiddleware } from '../utils/signRotateMiddleware';
import playerUpdateHandler from './actionHandlers/playerUpdate';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: socketGetMessage,
  effect: async (action, listenerApi) => {
    const { action: socketAction, payload: socketPayload } = action.payload;

    switch (socketAction) {
      case 'res:sync':
        syncHandler(listenerApi);
        break;
      case 'res:user:signin':
        signInUserHandler(listenerApi, socketPayload);
        break;
      case 'res:user:signup':
        signUpUserHandler(listenerApi, socketPayload);
        break;
      case 'res:user:logout':
        logoutHandler(listenerApi, socketPayload);
        break;
      case 'res:galaxy:list-for-choose':
        galaxyListHandler(listenerApi, socketPayload);
        break;
      case 'res:galaxy:choose':
        galaxyChooseHandler(listenerApi, socketPayload);
        break;
      case 'res:player:switch':
        playerSwitchHandler(listenerApi, socketPayload);
        break;
      case 'res:player:update':
        playerUpdateHandler(listenerApi, socketPayload);
        break;
      default:
        console.error(`!!! Socket action ${socketAction} has no handler`);
        break;
    }
  }
});

export const store = configureStore({
  reducer: {
    socket: socketReducer,
    sync: syncReducer,
    sign: signReducer,
    confirm: confirmReducer,
    user: userReducer,
    galaxy: galaxyReducer,
    time: timeReducer,
    resources: resourcesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
                                          .prepend(socketMiddleware(new Socket()))
                                          .prepend(signRotationMiddleware())
                                          .prepend(listenerMiddleware.middleware)
});



export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, any, Action>;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

