import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    background: black;
    font-family: Segoe UI, Montserrat, Open-Sans, Helvetica, Sans-Serif;
    color: #AAA;
    height: 100%;
    width: 100%;
  }
  
  button {
    font-family: Segoe UI, Montserrat, Open-Sans, Helvetica, Sans-Serif;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 300;
  }

  a, a:visited {
    color: #1F6FEB;
  }
`;
 
export default GlobalStyle;