import { ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { updateTime } from '../time';
import { updateResources } from '../resources';

const playerUpdateHandler = (listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>, payload: any) => {
  if (payload.time) {
    listenerApi.dispatch(updateTime(payload.time));
  }
  if (payload.resources) {
    listenerApi.dispatch(updateResources(payload.resources));
  }
}

export default playerUpdateHandler;

