const StatIcon = () => (
  <svg width="800px" height="800px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g id="Dribbble-Light-Preview" transform="translate(-420.000000, -2799.000000)" fill="currentColor">
        <g id="icons" transform="translate(56.000000, 160.000000)">
          <path d="M374,2656.982 C369.941,2656.982 366.588,2653.95 366.077,2650.028 L369.755,2650.034 C369.888,2650.035 370.016,2649.981 370.11,2649.886 L371.941,2648.05 C372.172,2647.819 372.56,2647.868 372.726,2648.151 C374.933,2651.92 374.491,2651.167 375.037,2652.094 C375.37,2652.659 376.146,2652.756 376.608,2652.292 L378.76,2650.138 C378.854,2650.044 378.982,2650 379.115,2650 L381.925,2650 C381.414,2654 378.059,2656.982 374,2656.982 M374,2640.964 C378.085,2640.964 381.458,2644 381.936,2648 L378.179,2648 C378.041,2648 377.909,2648.048 377.815,2648.148 L376.482,2649.559 C376.252,2649.802 375.853,2649.754 375.685,2649.464 L373.784,2646.179 L373.794,2646.172 L373.406,2645.504 C373.075,2644.937 372.297,2644.836 371.833,2645.301 L371.341,2645.795 L371.35,2645.78 L369.269,2647.847 C369.175,2647.939 369.049,2648 368.917,2648 L366.064,2648 C366.542,2644 369.915,2640.964 374,2640.964 M374,2639 C368.477,2639 364,2643.478 364,2649 C364,2654.523 368.477,2659 374,2659 C379.523,2659 384,2654.523 384,2649 C384,2643.478 379.523,2639 374,2639" id="stats-[#1193]" />
        </g>
      </g>
    </g>
  </svg>
);

export default StatIcon;
