import React from 'react';
import { InvisibleButton, SignErrorPlaseholder, SignErrorWrapper } from './styled';
import OcticonIcon from '../../icons/Octicon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { hideMessage } from '../../../store/user/user';

const SignError = () => {
  const { message, visible } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const onClickHandler = () => {
    dispatch(hideMessage());
  }

  return (
    <SignErrorPlaseholder>
      {message && visible && (
        <SignErrorWrapper>
          {message}
          <InvisibleButton
            type="button"
            onClick={onClickHandler}
          >
            <OcticonIcon fillColor="#EE0800" />
          </InvisibleButton>
        </SignErrorWrapper>
      )}
    </SignErrorPlaseholder>
  );
};

export default SignError;
