import { ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { signinUser } from '../user/user';
import { fetchSync } from '../sync/sync';

const signInUserHandler = (listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>, payload: any) => {
  listenerApi.dispatch(signinUser(payload));
  listenerApi.dispatch(fetchSync());
}

export default signInUserHandler;

