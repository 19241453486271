class Socket {
  
  private static socket: WebSocket | null = null;

  connect (url: string): boolean {
    if (!Socket.socket) {
      Socket.socket = new WebSocket(url);
      return true;
    }
    return false;
  }

  disconnect () {
    if (Socket.socket) {
      Socket.socket.close();
      Socket.socket = null;
    }
  }

  send (message: Object) {
    if (Socket.socket) {
      Socket.socket.send(JSON.stringify(message));
    }
  }

  on (eventName: string, callback: (evt: Event) => void) {
    if (Socket.socket) {
      Socket.socket.addEventListener(eventName, callback)
    }
  }
}

export default Socket;
