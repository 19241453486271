const WorkshopIcon = () => (
  <svg fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M458.362,196.923c-4.242-14.67-10.115-28.833-17.521-42.246l37.98-37.863L395.188,33.18l-37.863,37.98
			c-13.413-7.405-27.577-13.279-42.246-17.521V0H196.923v53.638c-14.67,4.242-28.833,10.115-42.246,17.521l-37.863-37.98
			L33.18,116.813l37.98,37.863c-7.405,13.413-13.279,27.577-17.521,42.246H0v118.154h53.638
			c4.242,14.67,10.115,28.833,17.521,42.246l-37.98,37.863l83.634,83.634l37.863-37.98c13.413,7.405,27.577,13.279,42.246,17.521
			V512h118.154v-53.638c14.67-4.242,28.833-10.115,42.246-17.521l37.863,37.98l83.634-83.634l-37.98-37.863
			c7.405-13.413,13.279-27.577,17.521-42.246H512v-0.001V196.923H458.362z M495.121,298.199H445.35l-1.618,6.357
			c-4.454,17.497-11.413,34.276-20.681,49.87l-3.358,5.649l35.239,35.132l-59.726,59.726l-35.132-35.239l-5.649,3.358
			c-15.594,9.269-32.373,16.228-49.87,20.681l-6.357,1.617v49.771h-84.396V445.35l-6.358-1.618
			c-17.497-4.454-34.276-11.413-49.87-20.681l-5.649-3.358l-35.132,35.239l-59.726-59.726l35.239-35.132l-3.358-5.649
			c-9.269-15.594-16.228-32.373-20.681-49.87l-1.617-6.357H16.879v-84.396H66.65l1.618-6.358
			c4.454-17.497,11.413-34.276,20.681-49.87l3.358-5.649l-35.239-35.132l59.726-59.726l35.132,35.239l5.649-3.358
			c15.594-9.269,32.373-16.228,49.87-20.681l6.357-1.617V16.879h84.396V66.65l6.358,1.618c17.497,4.454,34.276,11.413,49.87,20.681
			l5.649,3.358l35.132-35.239l59.726,59.726l-35.239,35.132l3.358,5.649c9.269,15.594,16.228,32.373,20.681,49.87l1.618,6.358h49.77
			V298.199z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,151.912c-57.395,0-104.088,46.693-104.088,104.088S198.605,360.088,256,360.088S360.088,313.395,360.088,256
			S313.395,151.912,256,151.912z M256,343.209c-48.086,0-87.209-39.122-87.209-87.209s39.122-87.209,87.209-87.209
			s87.209,39.122,87.209,87.209S304.086,343.209,256,343.209z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,208.176c-26.371,0-47.824,21.453-47.824,47.824c0,26.371,21.453,47.824,47.824,47.824
			c26.371,0,47.824-21.453,47.824-47.824C303.824,229.629,282.371,208.176,256,208.176z M256,286.945
			c-17.064,0-30.945-13.881-30.945-30.945s13.881-30.945,30.945-30.945s30.945,13.881,30.945,30.945S273.064,286.945,256,286.945z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="30.945" y="247.56" width="22.505" height="16.879" />
      </g>
    </g>
    <g>
      <g>
        <rect x="458.549" y="247.56" width="22.505" height="16.879" />
      </g>
    </g>
    <g>
      <g>
        <rect x="244.747" y="33.758" width="22.505" height="16.879" />
      </g>
    </g>
    <g>
      <g>
        <rect x="244.747" y="461.363" width="22.505" height="16.879" />
      </g>
    </g>
    <g>
      <g>
        <path d="M95.648,256h16.879c0-79.111,64.361-143.473,143.473-143.473V95.648C167.582,95.648,95.648,167.582,95.648,256z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M399.473,256c0,79.111-64.361,143.473-143.473,143.473v16.879c88.419,0,160.352-71.933,160.352-160.352H399.473z" />
      </g>
    </g>
  </svg>
);

export default WorkshopIcon;
