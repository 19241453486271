import styled from 'styled-components';

export const ButtonElement = styled.button`
  background-color: #0070FF;
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  font-weight: 200;
  text-align: center;
  padding: 13px 0 16px 0;
  margin: 20px 0 8px 0;
  font-size: 28px;
  cursor: pointer;

  &:hover {
    background-color: #007FFF;
  }

  &:disabled {
    background-color: #646464;
    color: #212121;
  }
`;
