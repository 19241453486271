import Socket from './socket';
import { logoutUser } from '../store/user/user';
import { socketGetMessage } from '../store/socket';
import isProduction from './isProduction';

export const socketConnectAction = () => {
  return {
    type: 'socket/connect'
  };
};

export const socketDisconnectAction = () => {
  return {
    type: 'socket/disconnect'
  }
}

export const socketSendAction = (data?: Object) => {
  return data ? { type: 'socket/send', payload: data } : { type: 'socket/send' };
}

export const socketMiddleware = (socket: Socket) => (params: any) => (next: any) => (action: any) => {
  const { dispatch, getState } = params;

  const { type, payload } = action;

  switch (type) {
    case 'socket/connect':
      if (socket.connect(isProduction() ? 'wss://gw-game.ru/io' : 'ws://localhost:5000/io')) {
        socket.on('open', () => {
          console.debug('> open socket');
        });

        socket.on('message', (evt: Event) => {
          try {
            console.log(`< [${(evt as MessageEvent).data}]`);

            const response = JSON.parse((evt as MessageEvent).data);

            if (!response.action) {
              throw new Error(`Illegal response format: ${JSON.stringify(response)}`);
            }

            dispatch(socketGetMessage({ action: response.action, payload: response.payload }));

          } catch (e) {
            console.error(e);            
          }
        });

        socket.on('close', () => {
          // @TODO: refactor (it's not logout process)
          // @TODO: show disconnected alert modal
          console.debug('close');
          // dispatch(removePlayer());
          dispatch(logoutUser());
        });
      }
      break;

    case 'socket/send':
      console.debug(`> [${JSON.stringify(payload)}]`);
      socket.send(payload);
      break;

    case 'socket/disconnect':
      socket.disconnect();
      break;

    default:
      break;
  }

  return next(action);
}
