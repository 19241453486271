import React from 'react';
import { Label, Wrapper } from './styled';
import Input from '../input';
import { RegisterOptions } from 'react-hook-form';

type LabeledInputProps = {
  label?: string;
  options?: RegisterOptions;
} & React.HTMLProps<HTMLInputElement>

const LabeledInput: React.FC<LabeledInputProps> = (props: LabeledInputProps) => {
  const { label, ...rest } = props;
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Input {...rest} />
    </Wrapper>
  )
};

export default LabeledInput;
