const SpaceIcon = () => (
  <svg fill="currentColor" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.853 512.853">
    <g transform="translate(1 1)">
      <g>
        <g>
          <path d="M348.867,246.893h-34.736c-1.405-10.05-5.253-19.253-10.945-27.001l26.055-26.759c3.413-3.413,3.413-8.533,0-11.947
				c-3.413-3.413-8.533-3.413-11.947,0l-26.35,26.35c-7.831-5.857-17.184-9.812-27.41-11.241V161.56c0-5.12-3.413-8.533-8.533-8.533
				s-8.533,3.413-8.533,8.533v34.736c-10.226,1.429-19.579,5.384-27.41,11.241l-26.35-26.35c-3.413-3.413-8.533-3.413-11.947,0
				s-3.413,8.533,0,11.947l26.35,26.35c-5.857,7.831-9.812,17.184-11.241,27.41h-34.736c-5.12,0-8.533,3.413-8.533,8.533
				s3.413,8.533,8.533,8.533h34.736c1.429,10.226,5.384,19.578,11.241,27.41l-26.35,26.35c-3.413,3.413-3.413,8.533,0,11.947
				c1.707,1.707,3.413,2.56,5.973,2.56s4.267-0.853,5.973-2.56l26.35-26.35c7.831,5.857,17.184,9.812,27.41,11.241v34.736
				c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-34.736c10.226-1.429,19.578-5.384,27.41-11.241l26.35,26.35
				c1.707,1.707,3.413,2.56,5.973,2.56s4.267-0.853,5.973-2.56c3.413-3.413,3.413-8.533,0-11.947l-26.35-26.35
				c5.857-7.831,9.812-17.184,11.241-27.41h34.736c5.12,0,8.533-3.413,8.533-8.533S353.987,246.893,348.867,246.893z
				 M287.279,283.572c-0.86,0.419-1.677,0.986-2.413,1.721s-1.303,1.553-1.721,2.413c-7.466,6.49-17.271,10.387-28.146,10.387
				s-20.68-3.897-28.146-10.387c-0.419-0.86-0.986-1.677-1.721-2.413c-0.735-0.735-1.553-1.303-2.413-1.721
				c-6.49-7.466-10.387-17.272-10.387-28.146c0-10.922,3.931-20.766,10.473-28.245c0.839-0.408,1.65-0.945,2.327-1.622
				c0.735-0.735,1.302-1.553,1.721-2.413c7.466-6.49,17.272-10.387,28.146-10.387s20.68,3.897,28.146,10.387
				c0.419,0.86,0.986,1.677,1.721,2.413c0.808,0.808,1.62,1.419,2.519,1.844c6.426,7.451,10.281,17.208,10.281,28.022
				C297.667,266.301,293.769,276.106,287.279,283.572z"/>
          <path d="M442.733,255.427c0-103.253-84.48-187.733-187.733-187.733c-67.635,0-129.684,35.881-163.039,94.534
				c-2.471-0.431-5.016-0.668-7.628-0.668c-23.893,0-42.667,18.773-42.667,42.667c0,17.879,10.513,32.888,25.815,39.313
				c-0.214,4.017-0.215,8.121-0.215,11.887c0,103.253,84.48,187.733,187.733,187.733S442.733,358.68,442.733,255.427z
				 M58.733,204.227c0-14.507,11.093-25.6,25.6-25.6c2.804,0,5.478,0.419,7.972,1.192l1.415,0.515
				c9.387,3.413,16.213,13.653,16.213,23.893c0,14.507-11.093,25.6-25.6,25.6C69.827,229.827,58.733,218.733,58.733,204.227z
				 M255,426.093c-93.867,0-170.667-76.8-170.667-170.667c0-2.56,0-5.973,0-8.533c23.893,0,42.667-18.773,42.667-42.667
				c0-14.875-7.278-27.763-18.536-35.374C139.231,117.028,194.566,84.76,255,84.76c93.867,0,170.667,76.8,170.667,170.667
				S348.867,426.093,255,426.093z"/>
          <path d="M511.853,254.573c0-52.703-16.093-102.864-46.584-146.268c12.214-9.38,20.131-24.112,20.131-40.612
				c0-28.16-23.04-51.2-51.2-51.2c-17.608,0-33.214,9.009-42.438,22.645C350.941,12.947,303.394-0.573,255-0.573
				c-140.8,0-256,115.2-256,256s115.2,256,256,256c42.731,0,84.697-10.67,121.586-31.28c7.765,8.76,19.143,14.213,32.014,14.213
				c23.893,0,42.667-18.773,42.667-42.667c0-8.917-2.617-17.12-7.137-23.901C487.996,379.883,511.853,318.82,511.853,254.573z
				 M434.2,33.56c18.773,0,34.133,15.36,34.133,34.133c0,17.995-14.116,32.845-31.82,34.046c-0.212,0.014-0.428,0.035-0.638,0.045
				c-0.555,0.027-1.114,0.043-1.676,0.043c-18.773,0-34.133-15.36-34.133-34.133c0-4.267,0.853-7.68,2.56-11.947l1.161-3.482
				C409.446,41.192,420.971,33.56,434.2,33.56z M255,494.36c-131.413,0-238.933-107.52-238.933-238.933S123.587,16.493,255,16.493
				c45.227,0,89.6,12.8,127.147,36.693l2.336,2.336c-0.834,3.388-1.324,6.906-1.441,10.515c-0.027,0.551-0.042,1.103-0.042,1.656
				c0,28.16,23.04,51.2,51.2,51.2c0.754,0,1.507-0.023,2.258-0.058c0.315-0.014,0.629-0.034,0.943-0.053
				c0.545-0.034,1.086-0.076,1.626-0.125c0.171-0.016,0.344-0.028,0.515-0.046c3.259-0.32,6.394-0.871,9.166-1.425l2.56,1.707
				c28.16,40.107,42.667,87.04,42.667,136.533c0,59.666-22.138,115.925-62.159,160.275c-5.747-3.662-12.465-5.975-19.748-6.532
				c-0.191-0.016-0.382-0.029-0.573-0.042c-0.324-0.021-0.648-0.041-0.974-0.055c-0.622-0.028-1.248-0.046-1.879-0.046
				c-23.893,0-42.667,18.773-42.667,42.667c0,0.692,0.019,1.367,0.05,2.03c0.019,0.419,0.052,0.833,0.083,1.248
				c0.013,0.164,0.022,0.333,0.037,0.496c0.295,3.5,0.997,6.865,2.059,10.055C333.258,484.182,294.13,494.36,255,494.36z
				 M408.6,477.293c-8.854,0-16.431-4.137-20.996-10.656l-1.191-2.144c-0.284-0.378-0.539-0.785-0.782-1.202
				c-1.5-3.069-2.413-6.512-2.596-10.203c-0.02-0.471-0.036-0.94-0.036-1.395c0-14.507,11.093-25.6,25.6-25.6
				c0.436,0,0.868,0.011,1.298,0.031c0.3,0.014,0.6,0.039,0.899,0.062c0.127,0.01,0.256,0.016,0.383,0.028
				c0.255,0.024,0.51,0.057,0.765,0.088c0.187,0.023,0.376,0.041,0.562,0.068c0.118,0.017,0.235,0.039,0.353,0.057
				c12.362,1.936,21.34,12.218,21.34,25.267C434.2,466.2,423.107,477.293,408.6,477.293z"/>
        </g>
      </g>
    </g>
  </svg>  
);

export default SpaceIcon;
