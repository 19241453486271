import { ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { fetchSync } from '../sync/sync';
import { signupUser } from '../user/user';

const signUpUserHandler = (listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>, payload: any) => {
  listenerApi.dispatch(signupUser(payload));
  listenerApi.dispatch(fetchSync());
}

export default signUpUserHandler;

