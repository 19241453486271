import React from 'react';
import { FormPanel, FormPlaceholder, PageWrapper } from './styled';
import GalaxyIcon from '../../icons/GalaxyIcon';
import SignError from '../SignError';

type SignLayoutProps = React.PropsWithChildren & {
  title?: string;
}

const SignLayout:React.FC<SignLayoutProps> = (props: SignLayoutProps) => {
  const { title, children } = props;

  return (
    <PageWrapper>
      <GalaxyIcon
        size={64}
        fillColor="#909090"
      />
      <FormPlaceholder>
        {title && <h1>{title}</h1>}
        <SignError />
        <FormPanel>
          {children}
        </FormPanel>
      </FormPlaceholder>
    </PageWrapper>
  )
};

export default SignLayout;
