import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { InputElement } from './styled';

type InputProps = {
  options?: RegisterOptions;
} & React.HTMLProps<HTMLInputElement>

const Input: React.FC<InputProps> = (props: InputProps) => {
  const { options, ...rest } = props;

  const { register } = useFormContext();

  return props.name ? (
    <InputElement
      {...register(props.name, props.options)}
      {...rest}
    />
  ) : null;
};

export default Input;
