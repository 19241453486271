import { useAppSelector } from '../../store';

const Calendar = () => {
  const { day, season, year } = useAppSelector((state) => state.time);
  return (
    <div>
      <span>Год {year}</span>
      <span>{season}</span>
      <span>день {day}</span>
    </div>
  )
};

export default Calendar;
