const TimeIcon = () => (
  <svg height="800px" width="800px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
    <g>
      <path d="M165.334,217.207l55.888,43.111c2.151,17.38,16.802,30.896,34.778,30.896c19.453,0,35.214-15.77,35.214-35.214
		c0-0.754-0.174-1.452-0.222-2.198l81.904-96.896c5.722-6.413,5.818-15.651,0.246-20.604c-5.579-4.968-14.73-3.777-20.436,2.635
		l-80.485,85.984c-4.873-2.556-10.341-4.135-16.222-4.135c-6.302,0-12.126,1.786-17.238,4.683l-52.626-36.643
		c-6.786-5-16.961-2.683-22.715,5.159C157.683,201.809,158.524,212.214,165.334,217.207z"/>
      <path d="M256,0c-11.222,0-20.317,9.096-20.317,20.318c0,11.222,9.096,20.317,20.317,20.317
		c59.54,0.008,113.246,24.072,152.286,63.079c39.008,39.032,63.072,92.746,63.079,152.286
		c-0.007,59.54-24.071,113.246-63.079,152.286c-39.04,39.008-92.746,63.071-152.286,63.079
		c-59.539-0.008-113.254-24.072-152.285-63.079C64.707,369.246,40.643,315.54,40.635,256c0.008-43.262,12.77-83.436,34.699-117.15
		l18.857,14.094c2.04,1.524,4.738,1.905,7.127,1c2.381-0.904,4.159-2.944,4.683-5.452l12.69-60.023
		c2.262-3.882,3.23-8.342,2.682-12.723l5.738-27.127c0.611-2.856-0.508-5.794-2.834-7.548c-2.342-1.738-5.476-1.976-8.048-0.579
		l-89.786,48.54c-2.254,1.23-3.722,3.5-3.904,6.047c-0.191,2.54,0.936,5.016,2.984,6.548l17.174,12.833
		C15.746,155.016-0.007,203.706,0,256c0.016,141.396,114.604,255.984,256,256c141.397-0.016,255.985-114.604,256-256
		C511.985,114.604,397.397,0.016,256,0z"/>
    </g>
  </svg>
);

export default TimeIcon;
