import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiMethods, callApi } from '../../utils/api';

export const fetchSync = createAsyncThunk(
  'game/sync',
  async (): Promise<syncActionPayload> => {
    return await callApi(apiMethods.GET, '/api/v1/sync', {});
  }
);

type syncState = {
  userId?: string;
  playerId?: string;
  result?: string;
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
}

type syncActionPayload = syncState;

const initialState: syncState = { status: 'idle' };

const syncSlice = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    removePlayer: (state) => {
      state.playerId = undefined;
    },
    removeUser: (state) => {
      state.userId = undefined;
      state.playerId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSync.fulfilled, (state: syncState, action) => {
      const { result, userId, playerId } = action.payload;
      state.result = result;
      state.playerId = playerId;
      state.userId = userId;
      state.status = 'succeeded';
    });
    builder.addCase(fetchSync.pending, (state: syncState, action) => {
      state.status = 'pending';
    });
    builder.addCase(fetchSync.rejected, (state: syncState, action) => {
      state.status = 'failed';
    });
  }
});

export const { removeUser } = syncSlice.actions;

export default syncSlice.reducer;
