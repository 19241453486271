import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import SignLayout from '../../components/sign/SignLayout';
import LabeledInput from '../../components/form/labeledInput';
import Button from '../../components/form/button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { resetError, setUserLoading } from '../../store/user/user';
import ButtonAsLink from '../../components/ButtonAsLink';
import { socketSendAction } from '../../utils/socketMiddleware';
import { ButtonErrorMessage, ButtonTitle, InlineButton } from './styled';

const SignUpPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onTouched' });

  const { formState: { errors, isSubmitting, isDirty, isValid } } = methods;
  const errorMessage = errors.email ? 'email не похож на правду' : (errors.password ? 'кажется, вы забыли заполнить пароль' : undefined);

  const { userId, playerId } = useAppSelector((state) => state.sync);

  useEffect(() => {
    if (userId) {
      navigate(playerId ? '/' : '/galaxy');
    }
  }, [navigate, userId, playerId]);

  const switchToSignIn = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(resetError());
    navigate('/signin');
  }

  const onSubmit = async (data: FieldValues) => {
    dispatch(setUserLoading(true));
    dispatch(socketSendAction({ action: 'req:user:signup', payload: data }));
  }

  return(
    <SignLayout title="Регистрация">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <LabeledInput
            type="email"
            label="Email адрес"
            name="email"
            autoComplete="email"
            required
            options={{ required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g }}
          />
          <LabeledInput
            type="password"
            label="Пароль"
            name="password"
            autoComplete="new-password"
            required
            options={{ required: true }}
          />
          <Button
            type="submit"
            disabled={isSubmitting}
          >
            <InlineButton>
              <ButtonTitle>Зарегистрироваться</ButtonTitle>
              {errorMessage && <ButtonErrorMessage>{errorMessage}</ButtonErrorMessage>}
            </InlineButton>
          </Button>
          <div className="sign-form-bottom">
            Уже зарегистрированы? <ButtonAsLink type="button" onClick={switchToSignIn}>Войти</ButtonAsLink>
          </div>
        </form>
      </FormProvider>
    </SignLayout>
  );
}

export default SignUpPage;
