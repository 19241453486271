import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import SignLayout from '../../components/sign/SignLayout';
import LabeledInput from '../../components/form/labeledInput';
import Button from '../../components/form/button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { socketSendAction } from '../../utils/socketMiddleware';
import ButtonAsLink from '../../components/ButtonAsLink';
import { resetError, setUserLoading } from '../../store/user/user';

const SignInPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm();

  const { userId, playerId } = useAppSelector((state) => state.sync);

  useEffect(() => {
    if (userId) {
      navigate(playerId ? '/' : '/galaxy');
    }
  }, [navigate, userId, playerId]);

  const switchToSignUp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(resetError());
    navigate('/signup');
  }

  const onSubmit = async (data: FieldValues) => {
    dispatch(setUserLoading(true));
    dispatch(socketSendAction({ action: 'req:user:signin', payload: data }));
  }

  return(
    <SignLayout title="Вход">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <LabeledInput
            type="text"
            label="Email адрес"
            name="email"
            autoComplete="email"
            required
          />
          <LabeledInput
            type="password"
            label="Пароль"
            name="password"
            autoComplete="current-password"
            required
          />
          <Button type='submit'>Войти</Button>
          <div className="sign-form-bottom">
            Новенький? <ButtonAsLink onClick={switchToSignUp}>Зарегистрироваться</ButtonAsLink>
          </div>
        </form>
      </FormProvider>
    </SignLayout>
  );
};

export default SignInPage;
